<template>
  <div class="place-container">
    <div class="place-head" v-if="+roleId !== 4 && role !== 'SUPER_ADMIN' && +isAdmin !== 2">
      <span>可用金额：{{ moneyType }}{{ numFormat(residueAmount, 3, '') }}</span>
      <a-button type="link" @click="handleSkipToCost">查看花费明细</a-button>
    </div>
    <div class="place-search">
      <div class="left">
        <m-select-more
          class="search-item"
          v-model="searchquery.publisherIdList"
          :allData="supplierList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          label="供应商"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :canSelectAll="true"
          @change="handleChangeSupplier"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.placeIdList"
          :allData="placeList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          label="广告位"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :canSelectAll="true"
          @change="handleSearch"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.positionIdList"
          :allData="positionList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="广告样式"
          :showSelectedPart="true"
          :hasSearch="false"
          width="450px"
          :canSelectAll="true"
          @change="handleSearch"
        />
        <m-select
          v-model="searchquery.sts"
          class="search-item"
          :dataList="stsList"
          :showSearch="false"
          label="状态"
          @change="handleSearch"
        />
      </div>
      <div class="right">
        <!-- <a-button type="primary" @click="handleEditPlace(true)">添加广告位</a-button> -->
        <a-dropdown-button
          :trigger="['click']"
          type="primary"
          style="border-radius: 0px !important"
          @click="handleEditPlace(true)"
        >
          <a-icon type="plus" />
          广告位
          <a-menu slot="overlay">
            <a-menu-item style="width: 130px" key="2" @click="handleBatchAddPlace">
              <a-icon type="plus-square" />批量添加
            </a-menu-item>
          </a-menu>
        </a-dropdown-button>
      </div>
    </div>
    <div class="main">
      <a-spin :spinning="isLoading">
        <el-table :data="dataList" style="width: 100%">
          <el-table-column prop="publisherName" label="供应商名称" min-width="160">
            <template slot-scope="{ row }">
              <div class="manager-text text-ellipsis" :title="row.publisherName">
                {{ row.publisherName }}
              </div>
              <div class="id-row">
                <span class="id manager-text">{{ row.publisherId }}</span>
                <CopySvg class="copy-icon percent-text" @click="copyId(row.publisherId)" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="广告位名称" min-width="160">
            <template slot-scope="{ row }">
              <div class="manager-text text-ellipsis" :title="row.name">
                {{ row.name }}
              </div>
              <div class="id-row">
                <span class="id manager-text">{{ row.customPlaceId || row.id }}</span>
                <CopySvg class="copy-icon percent-text" @click="copyId(row.customPlaceId || row.id)" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="requestList" label="7日流量请求数" width="240">
            <template slot-scope="{ row }">
              <RequestTableItem :row="row" />
            </template>
          </el-table-column>
          <el-table-column prop="advRequestList" label="7日广告请求" width="240">
            <template slot-scope="{ row }">
              <AdvRequestTableItem :row="row" />
            </template>
          </el-table-column>
          <el-table-column prop="positionName" label="广告样式" min-width="116">
          </el-table-column>
          <el-table-column prop="sts" label="状态">
            <template slot-scope="{ row }">
              <a-switch size="small" :checked="row.sts === 'A'" @click="handleChangeSts(row)"></a-switch>
            </template>
          </el-table-column>
          <!-- 只有平台运营展示合作状态 -->
          <el-table-column prop="cooperationSts" label="合作状态" min-width="80" v-if="+roleId === 4">
            <template slot-scope="{ row }">
              {{ row.cooperationSts === 'A' ? '合作中' : '暂停' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="258">
            <template slot-scope="{ row }">
              <span class="link-btn primary-text" @click="handleSkipToReport(row)">查看数据</span>
              <span class="link-btn primary-text" v-if="+isAdmin !== 2" @click="handleSkipToConfig(row)">配置详情</span>
              <m-icon-tips>
                <EditSvg class="link-btn" @click="handleEditPlace(false, row)" slot="icon"/>
                <template slot="title">编辑</template>
              </m-icon-tips>
              <m-icon-tips>
                <DeleteSvg class="link-btn" @click="handleClickDeleteBtn(row)" slot="icon"/>
                <template slot="title">删除</template>
              </m-icon-tips>
            </template>
          </el-table-column>
        </el-table>
        <a-pagination
          class="pagination"
          v-if="dataList.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
        <m-empty v-else style="height: 341px" imgHeight="176px">
          <template #description>暂无数据 </template>
        </m-empty>
      </a-spin>
    </div>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="广告位"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
    <EditPlaceModal
      :publisherIdList="searchquery.publisherIdList"
      :visible="editPlaceVisible"
      :isAdd="isAdd"
      :placeInfo="placeInfo"
      :supplierList="supplierList"
      :positionList="positionList"
      @modalCancel="editPlaceVisible = false"
      @editSuccess="() => {
        editPlaceVisible = false
        getDataList()
        getPlaceList(searchquery.publisherIdList)
      }"
    />
    <BatchAddPlaceModal
      @handleAddSuccess="
        () => {
          handleSearch()
          getPlaceList()
        }
      "
      :visible="batchAddVisible"
      @modalCancel="batchAddVisible = false"
    />
  </div>
</template>

<script>
import { getPlacePage, updatePlace, deletePlace } from '@/apiForManage/flow/place'
import { copy } from '@/utils/string'
import CopySvg from '@/assets/icons/copy.svg?inline'
import EditSvg from '@/assets/icons/edit.svg?inline'
import DeleteSvg from '@/assets/icons/delete.svg?inline'
import EditPlaceModal from '../components/EditPlaceModal'
import BatchAddPlaceModal from '../components/BatchAddPlaceModal'
import mixGetList from '@/mixins/getListForManage'
import RequestTableItem from '../Supplier/RequestTableItem'
import AdvRequestTableItem from '../Supplier/AdvRequestTableItem'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
export default {
  components: { CopySvg, EditPlaceModal, EditSvg, DeleteSvg, BatchAddPlaceModal, RequestTableItem, AdvRequestTableItem },
  mixins: [mixGetList],
  data () {
    return {
      delVisible: false,
      editPlaceVisible: false,
      batchAddVisible: false,
      isAdd: true,
      placeInfo: {},
      searchquery: {
        publisherIdList: [],
        placeIdList: [],
        positionIdList: [],
        sts: undefined,
        page: 1,
        limit: 10
      },
      total: 0,
      dataList: [],
      residueAmount: 0,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id,
      isAdmin: (state) => state.user.isAdmin,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  async created () {
    await this.getSupplierList()
    await this.getPlaceList()
    await this.getPositionList()
    this.getRouteQuery()
    this.handleSearch()
  },
  methods: {
    numFormat,
    handleSkipToCost () {
      this.$router.push({ name: 'cost' })
    },
    handleBatchAddPlace () {
      this.batchAddVisible = true
    },
    handleSkipToReport (row) {
      const name = +this.isAdmin === 2 ? 'mediareport' : 'synthreport'
      const url = this.$router.resolve({
        name,
        query: {
          placeId: row.id
        }
      })
      window.open(url.href, '_blank')
    },
    getRouteQuery () {
      const { query = {}, params = {} } = this.$route
      const { supplierId } = query
      if (supplierId) {
        this.searchquery.publisherIdList = [supplierId]
      }
      params.page && (this.searchquery = { ...this.searchquery, ...params })
    },
    async handleChangeSupplier (ids) {
      await this.getPlaceList(ids)
      this.handleSearch()
    },
    handleEditPlace (isAdd, row) {
      this.isAdd = isAdd
      if (!isAdd) {
        this.placeInfo = JSON.parse(JSON.stringify(row))
      }
      this.editPlaceVisible = true
    },
    handleSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getPlacePage(this.searchquery)
      const { items = [], total = 0, residueAmount = 0 } = data
      this.dataList = items
      this.total = total
      this.residueAmount = residueAmount
      this.isLoading = false
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleChangeSts (row) {
      updatePlace({
        ...row,
        sts: row.sts === 'A' ? 'S' : 'A'
      }).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success('修改成功')
          this.getDataList()
        }
      })
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    },
    handleClickDeleteBtn (row) {
      this.delVisible = true
      this.placeInfo = JSON.parse(JSON.stringify(row))
    },
    handleDeleteItem () {
      deletePlace({
        id: this.placeInfo.id
      }).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success('删除成功')
          this.delVisible = false
          this.handleSearch()
        }
      })
    },
    handleSkipToConfig (row) {
      this.$router.push({
        name: 'adplaceconfig',
        query: {
          placeId: row.id,
          placeName: row.name,
          supplierName: row.publisherName,
          supplierId: row.publisherId
        },
        params: {
          ...this.searchquery
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.place-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .place-head{
    background-color: #fff;
    padding: @smallMargin;
    margin-bottom: @mediumMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    border-radius: @mediumRadius;
    padding-top: @smallMargin;
  }
  .place-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .search-item {
        margin-right: @compMargin;
        width: 220px;
      }
    }
    .right {
      button {
        width: 130px;
      }
    }
  }
  .main {
    background-color: #fff;
    padding: @smallMargin;
    margin-top: @mediumMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    border-radius: @mediumRadius;
    padding-top: calc(@smallMargin - @thPadding);
    .id-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .id {
        margin-right: 6px;
      }
      .copy-icon {
        cursor: pointer;
        min-width: 12px;
        min-height: 12px;
      }
    }
    .link-btn {
      margin-right: 12px;
      cursor: pointer;
    }
    .pagination {
      margin-top: @smallMargin;
    }
  }
}
</style>
